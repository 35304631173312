// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_container__RLgP0 {\r\n    text-align: center;\r\n}\r\n\r\n.App_logo__xrTeC {\r\n    height: 40vmin;\r\n    pointer-events: none;\r\n}\r\n\r\n@media (prefers-reduced-motion: no-preference) {\r\n    .App_logo__xrTeC {\r\n        animation: App_App-logo-spin__FAuHd infinite 20s linear;\r\n    }\r\n}\r\n\r\n.App_header__CXytX {\r\n    background-color: #282c34;\r\n    min-height: 100vh;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    font-size: calc(10px + 2vmin);\r\n    color: white;\r\n}\r\n\r\n.App_link__p0tWo {\r\n    color: #61dafb;\r\n}\r\n\r\n@keyframes App_App-logo-spin__FAuHd {\r\n    from {\r\n        transform: rotate(0deg);\r\n    }\r\n    to {\r\n        transform: rotate(360deg);\r\n    }\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/containers/App/App.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI;QACI,uDAA4C;IAChD;AACJ;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".container {\r\n    text-align: center;\r\n}\r\n\r\n.logo {\r\n    height: 40vmin;\r\n    pointer-events: none;\r\n}\r\n\r\n@media (prefers-reduced-motion: no-preference) {\r\n    .logo {\r\n        animation: App-logo-spin infinite 20s linear;\r\n    }\r\n}\r\n\r\n.header {\r\n    background-color: #282c34;\r\n    min-height: 100vh;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    font-size: calc(10px + 2vmin);\r\n    color: white;\r\n}\r\n\r\n.link {\r\n    color: #61dafb;\r\n}\r\n\r\n@keyframes App-logo-spin {\r\n    from {\r\n        transform: rotate(0deg);\r\n    }\r\n    to {\r\n        transform: rotate(360deg);\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "App_container__RLgP0",
	"logo": "App_logo__xrTeC",
	"App-logo-spin": "App_App-logo-spin__FAuHd",
	"header": "App_header__CXytX",
	"link": "App_link__p0tWo"
};
export default ___CSS_LOADER_EXPORT___;
