import React from "react";
import styles from "./TopBar.module.css";
import PropTypes from "prop-types";

const topBar = ({title, user}) => (
    <div>
        <div className={styles.containerOne}>
            {/* <p className={styles.containerOneText}> {welcome}</p> */}
            <p className={styles.containerOneName}>
                {" "}
                {user && ` ${user.lastName}, ${user.firstName}`}{" "}
            </p>
        </div>
        <div className={styles.containerTwo}>{title}</div>
    </div>
);
topBar.propTypes = {
    title: PropTypes.string,
    user: PropTypes.object,
};

export default topBar;
