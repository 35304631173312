import React, {useContext, useReducer} from "react";
import reducer from "./reducer";
import axios from "axios";
import {
    LOGIN_USER_BEGIN,
    LOGIN_USER_ERROR,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
} from "./actions";
import {
    APPOINTMENT_VACCINE_DICTIONARY,
    buildEventsApi,
    ENCOUNTER_VACCINE_BATCH_NUMBER_DICTIONARY,
    PSTAGE_APPOINTMENTS,
    PSTAGE_ENCOUNTER,
    UUID_APPOINTMENT,
} from "../constants/DHIS2";

const user = sessionStorage.getItem("user");
const initialState = {
    isLoading: false,
    showLoginError: false,
    user: user ? JSON.parse(user) : null,
};

const AppContext = React.createContext();

const AppProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    /// Helper function to search for an attribute by its key or display name
    const findAttribute = (attributes, key) => {
        return attributes.find(attr => attr.displayName === key || attr.code === key)?.value;
    };
    /// REMOVE BEFORE THIS LINE

    /// Logs out user and removes them from session storage
    const logoutUser = () => {
        sessionStorage.removeItem("user");
        dispatch({type: LOGOUT_USER});
    };

    /// Begins the process for logging in a user
    const loginUser = async ({dateOfBirth, endPoint}) => {
        /*
                                     The login process can be broken down into several steps
                                     1. Verifying the user login information is correct
                                     2. Fetching all their appointment information
                                     3. Fetching all their encounter information
                                    */

        // Inform state that login has begun. Loading is set to true here
        dispatch({type: LOGIN_USER_BEGIN});
        // console.log("Login initiated...")

        // Get credentials from .env file
        const authCredentials = {
            username: process.env.REACT_APP_DHIS2_USERNAME,
            password: process.env.REACT_APP_DHIS2_PASSWORD,
        };
        try {
            // Fetch the user information from DHIS2
            //  console.log('Fetching user data from:', endPoint)
            // console.log('API Endpoint:', endPoint);

            const {data} = await axios.get(endPoint, {auth: authCredentials});

            // console.log("User data fetched:", data)
            const trackedEntity = data.trackedEntities[0];
            // Get the user's actual client information
            const actualDateOfBirth = findAttribute(trackedEntity.attributes, 'Date of Birth');
            ///   const actualDateOfBirth = data.trackedEntities[0].attributes[1].value;
            // Match the entered user's information to the actual user's information

            if (dateOfBirth === actualDateOfBirth) {
                //  console.log("Date of Birth matches");
                // ADDED FROM HERE

                const tid = trackedEntity.trackedEntity;  // Tracked Entity ID

                // Search for first name, last name, and CHID
                const fName = findAttribute(trackedEntity.attributes, 'First Name') || findAttribute(trackedEntity.attributes, 'FIRST_NAME');
                const lName = findAttribute(trackedEntity.attributes, 'Last Name') || findAttribute(trackedEntity.attributes, 'LAST_NAME');
                const chidFull = findAttribute(trackedEntity.attributes, 'CHID') || findAttribute(trackedEntity.attributes, 'Unique System Identifier (CHID)');
                const chid = chidFull ? chidFull.substring(5) : null;
                /// const tid = data.trackedEntities[0].trackedEntity;
                ///  const fName = data.trackedEntities[0].attributes[3].value;  // First Name at index 4
                /// const lName = data.trackedEntities[0].attributes[6].value;  // Last Name at index 5
                /// const chid = data.trackedEntities[0].attributes[3].value.substring(5);  // CHID at index 15

                // Begin Appointment Data
                const appointmentsUrl = buildEventsApi({
                    programStage: PSTAGE_APPOINTMENTS,
                    trackedEntityInstanceId: tid,
                });
                const {data: appointmentsData} = await axios.get(appointmentsUrl, {
                    auth: authCredentials,
                });
                // Initialize empty list to hold appointment information
                const appointments = [];
                // Begin traversing all user appointment information, collecting necessary information
                appointmentsData.events.forEach((event) => {
                    // A representation of an appointment of a user
                    const agenda = {
                        date: new Date(),
                        vaccines: [],
                        location: event.orgUnitName,
                    };
                    // Go through the data contained within each event
                    event.dataValues.forEach((dataValue) => {
                        // If the data element is an appointment, convert string date into JavaScript date
                        if (dataValue.dataElement === UUID_APPOINTMENT) {
                            agenda.date = new Date(`${dataValue.value} EST`);
                        }
                        // If a data element is a vaccine data element & is recorded as true, add to agenda vaccines
                        if (
                            dataValue.dataElement in APPOINTMENT_VACCINE_DICTIONARY &&
                            dataValue.value === "true"
                        ) {
                            agenda.vaccines.push(
                                APPOINTMENT_VACCINE_DICTIONARY[dataValue.dataElement]
                            );
                        }
                    });
                    // Push agenda to list of appointments
                    appointments.push(agenda);
                });
                // Begin getting vaccine encounter information
                const encounterUrl = buildEventsApi({
                    programStage: PSTAGE_ENCOUNTER,
                    trackedEntityInstanceId: tid,
                });


                const {data: encountersData} = await axios.get(encounterUrl, {
                    auth: authCredentials,
                });
                // Instantiate a list of encounters and a dictionary containing the doses for all vaccines
                const encounters = [];
                const dosages = {};
                // Create a dictionary containing all vaccine names, and set each value to zero (0)
                Object.values(ENCOUNTER_VACCINE_BATCH_NUMBER_DICTIONARY).forEach(
                    (vaccineName) => {
                        dosages[vaccineName] = 0;
                    }
                );
                // Sort encounters by date
                encountersData.events.sort((a, b) => {
                    return new Date(a.eventDate) - new Date(b.eventDate);
                });
                // Begin traversing encounters data
                encountersData.events.forEach((event) => {
                    if (event.status === 'SCHEDULE') {
                        return;  // Skip this encounter
                    }
                    // Convert string date to JavaScript date
                    const date = new Date(`${event.eventDate.substring(0, 10)} EST`);
                    // Begin creating encounter data object, default country to Dominica
                    const encounter = {date: date, vaccines: [], country: "Dominica"};
                    // Begin traversing each data value within the event
                    event.dataValues.forEach((dataValue) => {
                        // If the data element is a vaccine dosage number
                        if (
                            dataValue.dataElement in ENCOUNTER_VACCINE_BATCH_NUMBER_DICTIONARY
                            /*
                                                         It is more efficient to look for vaccines by their batch number.
                                                         This immediately allows one to discern a vaccine name, increment dosage and
                                                         the batch number, especially considering that vaccine name data element
                                                         is scattered across the API response.
                                                        */
                        ) {
                            // Increment dosage for that vaccine
                            dosages[
                                ENCOUNTER_VACCINE_BATCH_NUMBER_DICTIONARY[dataValue.dataElement]
                                ] += 1;
                            // Create vaccine object
                            const vaccine = {
                                // Vaccine name gained by referencing the batch number dictionary
                                vaccineName:
                                    ENCOUNTER_VACCINE_BATCH_NUMBER_DICTIONARY[
                                        dataValue.dataElement
                                        ],
                                // Vaccine batch number is simply the data value's value
                                batchNumber: dataValue.value,
                                // Vaccine dosage number gained from dosages dictionary.
                                dosageNumber:
                                    dosages[
                                        ENCOUNTER_VACCINE_BATCH_NUMBER_DICTIONARY[
                                            dataValue.dataElement
                                            ]
                                        ],
                            };
                            // Push the vaccine to the vaccine list for this specific encounter
                            encounter.vaccines.push(vaccine);
                        }
                    });
                    // Add encounter to list of encounters
                    encounters.push(encounter);
                });

                // Build user Object using information gained from the APIs
                const user = {
                    trackedEntityId: tid,
                    firstName: fName,
                    lastName: lName,
                    dob: actualDateOfBirth,
                    chid: chid,
                    appointments: appointments.sort((a, b) => a.date - b.date),
                    encounters: encounters,
                };
                // Add user to session storage in order to have it persist through refresh
                sessionStorage.setItem("user", JSON.stringify(user));
                dispatch({type: LOGIN_USER_SUCCESS, payload: {user}});
            }
            // This else is hit when the user credentials are incorrect

            else {
                console.log("Does not match!");
                dispatch({type: LOGIN_USER_ERROR, msg: "INCORRECT_DOB"});
            }
        } catch (error) {
            // This error occurs when there is something wrong with DHIS2 api response
            dispatch({type: LOGIN_USER_ERROR, msg: "INCORRECT_DOB"});
        }
    };

    return (
        <AppContext.Provider
            value={{
                ...state,
                logoutUser,
                loginUser,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

const useAppContext = () => {
    return useContext(AppContext);
};

export {AppProvider, initialState, useAppContext};
