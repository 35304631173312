import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import Styles from "./Calender.module.css";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameDay,
  isToday,
  parse,
  startOfToday,
} from "date-fns";
import { useState } from "react";
import { useAppContext } from "../../context/appContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Calender() {
  const { user } = useAppContext();
  let today = startOfToday(); //Today originally represents today's date as obtained from date fns.
  let [selectedDay, setSelectedDay] = useState(today); // react function to set the variable today to the selected date if any.
  let [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy")); //react function to set the current month using today's date.
  let firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date()); //react function to set the first day of the current month from the variable current month.

  let days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  });

  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  const appointmentDates = user.appointments.map((appointment) => {
    //maps each appointment date of the user into the variable appointmentDates.
    return new Date(appointment.date);
  });

  return (
    <div
      className={Styles.everything} //this div holds both the calendar and the appointments
    >
      <div className={Styles.Container}>
        <div className={Styles.ContainerTwo}>
          <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200 ">
            <div className={Styles.CalenderContainer}>
              <h2
                className={Styles.Month} //current month
              >
                {format(firstDayCurrentMonth, "MMMM yyyy")}
              </h2>
              <div className="flex items-center">
                <button
                  onClick={previousMonth}
                  className={Styles.buttons} //holds the button to move to the previous month
                >
                  <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                </button>
                <div className={Styles.weekDays}>
                  <div>S</div>
                  <div>M</div>
                  <div>T</div>
                  <div>W</div>
                  <div>T</div>
                  <div>F</div>
                  <div>S</div>
                </div>

                <button
                  onClick={nextMonth}
                  className={Styles.buttons} //holds the button to move to the next month.
                >
                  <span className="sr-only">Next month</span>
                  <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>
              <div className={Styles.dates}>
                {days.map((day, dayIdx) => (
                  <div
                    //function which maps each date of the month into a button
                    key={day.toString()}
                    className={classNames(
                      dayIdx === 0 && colStartClasses[getDay(day)],
                      "py-1.5"
                    )}
                  >
                    <button
                      onClick={() => setSelectedDay(day)}
                      className={classNames(
                        isEqual(day, selectedDay) && `${Styles.textWhite}`, //if selected day is today change the text to white.

                        !isEqual(day, selectedDay) &&
                          isToday(day) &&
                          `${Styles.textYellow}`, //if the selected day is not today, change today's text to bolded yellow

                        isEqual(day, selectedDay) &&
                          isToday(day) &&
                          `${Styles.yellowBg}`, //if the day and the selected day are the same as today have a yellow background.

                        isEqual(day, selectedDay) &&
                          !isToday(day) &&
                          `${Styles.greyBg}`,

                        //maps each appointment date onto the function today from date fns; if there is an appointment that is not the same as today's (some function) it provides the appointment notifier (blue background)
                        appointmentDates.some(
                          (appointmentDate) =>
                            isSameDay(appointmentDate, day) && !isToday(day)
                        ) && `${Styles.appointmentNotifier}`,

                        // maps each appointment date onto the function today from date fns; if there is an appointment that is the same as today (some function) it provides a ring around today's date.
                        appointmentDates.some(
                          (appointmentDate) =>
                            isSameDay(appointmentDate, day) && isToday(day)
                        ) && `${Styles.appointmentNotifierRing}`,

                        !isEqual(day, selectedDay) && "hover:bg-gray-200",
                        "mx-auto h-8 w-8 items-center justify-center rounded-full mt-1"
                      )}
                    >
                      <time dateTime={format(day, "yyyy-MM-dd")}>
                        {format(day, "d")}
                      </time>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={Styles.vaccineContainer}>
        <section className="mt-12 md:mt-0 md:pl-14">
          <h3 className={Styles.thisYear}>This Year </h3>
          {user.appointments.map((appointment) => {
            //maps each appointment in the user to get the appointment's year
            if (
              new Date(appointment.date).getFullYear() === //if the appointment is in the current year it displays the following
              new Date().getFullYear()
            ) {
              return (
                <div
                  className={Styles.appointmentCard} //appointment card
                >
                  <h4
                    className={Styles.cardLocation} //appointment's location
                  >
                    {`${appointment.location}`}
                  </h4>
                  <h1 className={Styles.cardDate1}>
                    {`${new Date(appointment.date).toLocaleDateString("en-us", {
                      weekday: "long", //appointment's weekday
                      day: "numeric", //appointment's date
                    })}`}
                  </h1>
                  <h1 className={Styles.cardDate2}>
                    {`${new Date(appointment.date).toLocaleDateString("en-us", {
                      month: "long", //appointment's month
                    })}`}
                  </h1>
                  <h4 className={Styles.cardSubtitle}>
                    {" "}
                    {
                      //lists out vaccines registered for the appointment
                    }
                    {`${appointment.vaccines.join(",  ")}`}
                  </h4>
                </div>
              );
            } else {
              return (
                <div>
                  <h3 className={Styles.upcomingYear}>
                    {new Date(appointment.date).getFullYear()}
                  </h3>
                  <div className={Styles.appointmentCard}>
                    <h4 className={Styles.cardLocation}>
                      {`${appointment.location}`}
                    </h4>
                    <h1 className={Styles.cardDate1}>
                      {`${new Date(appointment.date).toLocaleDateString(
                        "en-us",
                        {
                          weekday: "long",
                          day: "numeric",
                        }
                      )}`}
                    </h1>
                    <h1 className={Styles.cardDate2}>
                      {`${new Date(appointment.date).toLocaleDateString(
                        "en-us",
                        {
                          month: "long",
                        }
                      )}`}
                    </h1>
                    <h4 className={Styles.cardSubtitle}>
                      {" "}
                      {`${appointment.vaccines.join(",  ")}`}
                    </h4>
                  </div>
                </div>
              );
            }
          })}
        </section>
      </div>
    </div>
  );
}

let colStartClasses = [
  "",
  "col-start-2",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
  "col-start-7",
];
