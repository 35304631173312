// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (min-width: 769px) {\r\n    .Appointments_appointments__90AHV {\r\n\r\n        margin-left: 300px;\r\n    }\r\n\r\n}", "",{"version":3,"sources":["webpack://./src/screens/Appointments/Appointments.module.css"],"names":[],"mappings":"AAAA;IACI;;QAEI,kBAAkB;IACtB;;AAEJ","sourcesContent":["@media screen and (min-width: 769px) {\r\n    .appointments {\r\n\r\n        margin-left: 300px;\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appointments": "Appointments_appointments__90AHV"
};
export default ___CSS_LOADER_EXPORT___;
