import React, { useEffect } from "react";
import TopBar from "../../components/TopBar";
import Styles from "./Login.module.css";
import Popup from "reactjs-popup";
import FormField from "../../components/FormField";
import Photo from "../../coatOfArms.png";
import { ORGANIZATION_UNIT_DOMINICA, PROGRAM_EIR } from "../../constants/DHIS2";
import { useAppContext } from "../../context/appContext";
import { Navigate, useNavigate } from "react-router-dom";

export default function Login() {
  const { loginUser, user, showLoginError, isLoading } = useAppContext();
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState({
    CHID: "",
    dateOfBirth: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    loginUser({
      dateOfBirth: formData.dateOfBirth,
      endPoint: `https://dhis2test.opensolutions.health/api/tracker/trackedEntities/?filter=KSr2yTdu1AI:EQ:CHID_${formData.CHID}&orgUnit=${ORGANIZATION_UNIT_DOMINICA}&ouMode=DESCENDANTS&program=${PROGRAM_EIR}`,
    });
  };

  // If user object has changed, perform redirect
  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/appointments");
      }, 3000);
    }
  }, [user, navigate]);
  if (user) {
    return <Navigate to="/appointments" />;
  }
  return (
    <div>
      <TopBar />
      <div className={Styles.loginContainer}>
        <img src={Photo} className={Styles.logo} alt="Coat of Arms" />
        <h1 className={Styles.title}>Dominica Vaccine Portal</h1>
        <br />
        {showLoginError && (
          <div className={Styles.error}>
            Incorrect Credentials. Please try again!
          </div>
        )}
        <div className={Styles.formContainer}>
          <form onSubmit={onSubmit}>
            <h3 className={Styles.signIn}>Sign-In</h3>
            <p className={Styles.instructions}>
              Enter your details below to login
            </p>
            <FormField
              disabled={isLoading}
              id="CH-ID"
              type="text"
              onChange={handleChange}
              name="CHID"
              value={formData.CHID}
              placeholder="CHID_"
            />
            <FormField
              disabled={isLoading}
              id="Date of Birth (D.O.B)"
              type="date"
              placeholder="dd/mm/yyyy"
              onChange={handleChange}
              name="dateOfBirth"
              value={formData.dateOfBirth}
            />
            <button
              value="Login"
              className={Styles.submitButton}
              disabled={isLoading}
            >
              Login
            </button>
          </form>

          <Popup
            trigger={
              <button className={Styles.unsure}> Unsure of CH-ID?</button>
            }
          >
            <div className={Styles.helpContainer}>
              Contact your Local Health Center for more information
            </div>
          </Popup>
        </div>
        <br />
      </div>
    </div>
  );
}
