import React, {useState} from "react";
import TopBar from "../../components/TopBar";
import styles from "../Records/Records.module.css";
import Card from "../../components/Card";
import NavBar from "../../components/NavBar";
import {useAppContext} from "../../context/appContext";
import {Navigate} from "react-router-dom";
import Certificate from "../../components/Certificate";
import LogoutButton from "../../components/LogoutButton";

export default function Records() {
    const {user} = useAppContext();

    const vaccines = [
        ...new Set(
            user?.encounters
                .map((encounter) => {
                    return encounter.vaccines.map((vaccine) => {
                        return vaccine.vaccineName;
                    });
                })
                .flat()
        ),
    ];
    const initialState = vaccines?.map((vaccine) => {
        return {
            vaccineName: vaccine,
            checked: false,
            key: vaccines.indexOf(vaccine),
        };
    });

    const [values, setValues] = useState(initialState);
    const [numberSelected, setNumberSelected] = useState(0);
    const [showCertificate, setShowCertificate] = useState(false);

    if (!user) {
        return <Navigate to="/"/>;
    }
    const getNumSelected = (values) => {
        return values?.filter((card) => card.checked).length;
    };
    const toggleSelectAll = () => {
        // If currently all are selected, turn off.
        const newValues = [];
        values?.map((value) => {
            value = {
                ...value,
                checked: !(numberSelected === values.length),
            };
            return newValues.push(value);
        });
        setValues(newValues);
        setNumberSelected(getNumSelected(newValues));
    };

    const toggleCheckbox = (id) => {
        values[id].checked = !values[id].checked;
        setValues(values);
        setNumberSelected(getNumSelected(values));
    };

    if (showCertificate) {
        return (
            <Certificate
                selectionData={user.encounters
                    .filter((encounter) => {
                        return encounter.vaccines.some((vaccine) => {
                            return values
                                .filter((value) => value.checked)
                                .map((value) => value.vaccineName)
                                .includes(vaccine.vaccineName);
                        });
                    })
                    .map((encounter) => {
                        return {
                            country: encounter.country,
                            date: encounter.date,
                            vaccines: encounter.vaccines.filter((vaccine) => {
                                return values
                                    .filter((value) => value.checked)
                                    .map((value) => value.vaccineName)
                                    .includes(vaccine.vaccineName);
                            }),
                        };
                    })}
                goBack={() => setShowCertificate(false)}
            />
        );
    } else
        return (
            <>
                <TopBar title="Records" user={user}/>
                <LogoutButton/>
                <div className={styles.mainContainer}>
                    <>
                        <h3>Select Vaccine(s)</h3>
                        <hr/>
                    </>
                    <div className={styles.selectItems}>
                        {" "}
                        {/* resize margins based on screen size */}
                        <h4 className={styles.selected}>{numberSelected} Selected</h4>{" "}
                        {/* show once selected */}
                        <button className={styles.unselect} onClick={toggleSelectAll}>
                            {numberSelected === values.length ? "Deselect All" : "Select All"}
                        </button>
                        {/* switch to "Deselect All" once clicked */}{" "}
                        {/* switch to "Deselect All" once clicked */}
                    </div>
                    <>
                        {values.map((value) => {
                            return (
                                <Card
                                    vaccineName={value.vaccineName}
                                    key={value.key}
                                    isChecked={value.checked}
                                    id={value.key}
                                    onCardClicked={() => {
                                        toggleCheckbox(value.key);
                                    }}
                                />
                            );
                        })}
                    </>
                    <button
                        className={styles.viewButton}
                        disabled={numberSelected < 1}
                        onClick={() => setShowCertificate(true)}
                    >
                        View Certificate
                    </button>
                    {/* only shows when a vaccine is selected */}
                    <NavBar/>
                </div>
            </>
        );
}
