import React from "react";
import styles from "./FormField.module.css";

export default function FormField({
  id = "",
  type = "text",
  onChange = () => {},
  name = "",

  value = "",
  placeholder = "",
  isDisabled = false,
}) {
  return (
    <div>
      <label htmlFor={id} className={styles.label}>
        {id}
      </label>
      <input
        id={id}
        type={type}
        onChange={onChange}
        name={name}
        value={value}
        placeholder={placeholder}
        className={styles.box}
        disabled={isDisabled}
      />
    </div>
  );
}
