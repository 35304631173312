import React from "react";
import styles from "./LogoutButton.module.css";
import { IconLogout } from "@tabler/icons";
import { useAppContext } from "../../context/appContext";

export default function LogoutButton() {
  const { logoutUser } = useAppContext();
  return (
    <div>
      {/* Displays the logout button */}
      <button className={styles.logoutBtn} onClick={() => logoutUser()}>
        {/* Once the user clicks the button, they are redirected to the login screen */}
        <IconLogout /> {/* the icon for the logout button */}
      </button>
    </div>
  );
}
