import React from "react";
import styles from "./NavBar.module.css";
import { NavLink } from "react-router-dom";
import { IconCalendarEvent, IconCertificate } from "@tabler/icons";

export default function NavBar() {
  return (
    <div>
      <ul>
        {" "}
        {/* unordered list for both navigation options */}
        <div className={styles.navBar}>
          <div className={styles.iconA}>
            <NavLink //directs user to appointment screen when clicked
              to="/appointments"
              exact
              style={({ isActive }) => ({
                color: isActive ? "black" : "gray", //when active, this option will be in black, when inactive, option is grayed out
              })}
            >
              <IconCalendarEvent className={styles.appointmentIcon} />{" "}
              {/* icon for appointments */}
              <p className={styles.appointmentText}>Appointments</p>
            </NavLink>
          </div>
          <div className={styles.iconB}>
            <NavLink //directs user to records screen when clicked
              to="/records"
              exact
              style={({ isActive }) => ({
                color: isActive ? "black" : "gray", //when active, this option will be in black, when inactive, option is grayed out
              })}
            >
              <IconCertificate className={styles.certificateIcon} />{" "}
              {/* icon for records */}
              <p className={styles.recordText}>Records</p>
            </NavLink>
          </div>
        </div>
      </ul>
    </div>
  );
}
