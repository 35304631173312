// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormField_label__ktL7k {\r\n    font-weight: 600;\r\n    font-size: 16px;\r\n    display: flex;\r\n    justify-content: center;\r\n    margin-top: 10px;\r\n\r\n}\r\n\r\n.FormField_box__Z-gr2 {\r\n    text-align: center;\r\n    background: rgba(217, 217, 217, 0.65);\r\n    border-radius: 46px;\r\n    display: block;\r\n    margin: 5px auto;\r\n    height: 25px;\r\n    width: 200px;\r\n}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/components/FormField/FormField.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,gBAAgB;;AAEpB;;AAEA;IACI,kBAAkB;IAClB,qCAAqC;IACrC,mBAAmB;IACnB,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".label {\r\n    font-weight: 600;\r\n    font-size: 16px;\r\n    display: flex;\r\n    justify-content: center;\r\n    margin-top: 10px;\r\n\r\n}\r\n\r\n.box {\r\n    text-align: center;\r\n    background: rgba(217, 217, 217, 0.65);\r\n    border-radius: 46px;\r\n    display: block;\r\n    margin: 5px auto;\r\n    height: 25px;\r\n    width: 200px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "FormField_label__ktL7k",
	"box": "FormField_box__Z-gr2"
};
export default ___CSS_LOADER_EXPORT___;
