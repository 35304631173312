import React, {useRef} from "react";
import Styles from "./Certificate.module.css";
import photo from "../../coatOfArms.png";
import Table from "../../components/Table";
import {useAppContext} from "../../context/appContext";
import PropTypes from "prop-types";
import ReactToPrint from "react-to-print";
import {IconArrowBack, IconDownload} from "@tabler/icons";

export default function Certificate(props) {
    const componentRef = useRef();
    const {user} = useAppContext();
    const TableData = props.selectionData.map((encounter) => {
        return encounter.vaccines.map((vaccine) => {
            return (
                <Table
                    vName={vaccine.vaccineName}
                    vBatchNum={vaccine.batchNumber}
                    dateReceived={`${new Date(encounter.date).toLocaleDateString(
                        "en-us",
                        {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        }
                    )}`}
                    doseNum={vaccine.dosageNumber}
                    countryOfVaccination={encounter.country}
                />
            );
        });
    });
    return (
        <div>
            <div className={Styles.page} ref={componentRef}>
                <img src={photo} alt="Coat of Arms" className={Styles.Header}/>
                <h5 className={Styles.headerText}>Commonwealth of Dominica</h5>
                <div className={Styles.greenBlock}>
                    <h1 className={Styles.greenBlockHeading}>
                        Digital Vaccination Certificate
                    </h1>
                    <div className={Styles.greenBlockData}>
                        <div className={Styles.UserHeadingLabel}>
                            {/*  <h6>CH-ID:</h6> */}
                            <h6>Name:</h6>
                            <h6>Date of Birth (D.O.B) :</h6>
                        </div>
                        <div className={Styles.UserHeadingInfo}>
                            {/*    <h6>#######</h6> */}
                            <h6 className={Styles.userName}>
                                {user.lastName}, {user.firstName}
                            </h6>
                            <h6>{user.dob}</h6>
                        </div>
                    </div>
                    <div className={Styles.YellowBlock}>
                        <p>
                            This document certifies that this individual has received the
                            following vaccination(s) listed
                        </p>
                    </div>
                </div>
                <div className={Styles.display}>
                    <img src={photo} alt="Coat of Arms" className={Styles.Watermark}/>
                    <table className={Styles.table}>
                        <thead className={Styles.tableHeadings}>
                        <th>Vaccine Name</th>
                        <th>Vaccine Batch Number</th>
                        <th>Date Received</th>
                        <th>Dose Number</th>
                        <th>Country of Vaccination</th>
                        </thead>
                        {TableData}
                    </table>
                </div>
                <div>
                    <h5 className={Styles.issueStatement}>
                        This document is issued by the Commonwealth of Dominica
                    </h5>
                    <p className={Styles.releaseStatement}>
                        This document was retrieved on {printReleaseDate()}
                    </p>
                </div>
            </div>
            <ReactToPrint
                trigger={() => (
                    <button className={Styles.downloadBtn}>
                        <IconDownload/>
                    </button>
                )}
                content={() => componentRef.current}
            />
            <button className={Styles.homeBtn} onClick={props.goBack}>
                <IconArrowBack/>
            </button>
        </div>
    );
}

function printReleaseDate() {
    const d = new Date();
    return d.toLocaleString("en-us", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
}

Certificate.propTypes = {
    selectionData: PropTypes.array.isRequired,
    goBack: PropTypes.func.isRequired,
};
