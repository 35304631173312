import styles from "./App.module.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../../screens/Login";
import Appointments from "../../screens/Appointments";
import Records from "../../screens/Records";

function Index() {
  return (
    <Router>
      <Routes className={styles.container}>
        <Route path="/" element={<Login />} />
        <Route path="/appointments" element={<Appointments />} />
        <Route path="/records" element={<Records />} />
      </Routes>
    </Router>
  );
}

export default Index;
