import React from "react";
import TopBar from "../../components/TopBar";
import Calendar from "../../components/Calender";
import NavBar from "../../components/NavBar";
import styles from "./Appointments.module.css";
import {Navigate} from "react-router-dom";
import {useAppContext} from "../../context/appContext";
import LogoutButton from "../../components/LogoutButton";

export default function Appointments() {
    const {user} = useAppContext();
    if (!user) {
        return <Navigate to="/"/>;
    }

    return (
        <div>
            <TopBar title="Appointments" user={user}/>
            <LogoutButton/>
            <div className={styles.appointments}>
                <Calendar/>
            </div>
            <NavBar/>
        </div>
    );
}
