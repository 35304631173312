import React from "react";
//vaccine abbreviated to v

export default function Table(props) {
  return (
    <tr>
      <td>{props.vName}</td>
      <td>{props.vBatchNum}</td>
      <td>{props.dateReceived}</td>
      <td>{props.doseNum}</td>
      <td>{props.countryOfVaccination}</td>
    </tr>
  );
}
