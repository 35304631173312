// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LogoutButton_logoutBtn__BUbJc {\r\n    background-color: #FCD116;\r\n    border-radius: 12px;\r\n    color: #2C3E50;\r\n    position: absolute;\r\n    top: 30px;\r\n    right: 45px;\r\n    width: 40px;\r\n    height: 40px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n@media screen and (min-width: 769px) {\r\n    .LogoutButton_logoutBtn__BUbJc {\r\n        background: #FEE88B;\r\n        top: 650px;\r\n        position: fixed;\r\n        left: 180px;\r\n        right: 550px;\r\n        z-index: 2;\r\n    }\r\n}", "",{"version":3,"sources":["webpack://./src/components/LogoutButton/LogoutButton.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI;QACI,mBAAmB;QACnB,UAAU;QACV,eAAe;QACf,WAAW;QACX,YAAY;QACZ,UAAU;IACd;AACJ","sourcesContent":[".logoutBtn {\r\n    background-color: #FCD116;\r\n    border-radius: 12px;\r\n    color: #2C3E50;\r\n    position: absolute;\r\n    top: 30px;\r\n    right: 45px;\r\n    width: 40px;\r\n    height: 40px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n@media screen and (min-width: 769px) {\r\n    .logoutBtn {\r\n        background: #FEE88B;\r\n        top: 650px;\r\n        position: fixed;\r\n        left: 180px;\r\n        right: 550px;\r\n        z-index: 2;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoutBtn": "LogoutButton_logoutBtn__BUbJc"
};
export default ___CSS_LOADER_EXPORT___;
