import React from "react";
import styles from "./Card.module.css";
import PropTypes from "prop-types";

export default function Card(props) {
  return (
    <div>
      <div className={styles.Container}>
        <span>
          <input
            //checkbox input field for selecting vaccines
            type="checkbox"
            className={styles.checkbox}
            checked={props.isChecked}
            onClick={props.onCardClicked}
          />
        </span>
        <div className={styles.vaccineName}>
          {/* Displays the names of vaccines */}
          <h1>{props.vaccineName}</h1>
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onCardClicked: PropTypes.func.isRequired,
};
