/// DHIS2 Data Elements UUIDs

export const DATE_OF_BIRTH = "HCsplhS6M0r";
export const ORGANIZATION_UNIT_DOMINICA = "Ja1KnAaWsDu";
export const UUID_CHID = "KSr2yTdu1AI";
export const UUID_APPOINTMENT = "dqYTmh865Hf";
export const PROGRAM_EIR = "SSLpOM0r1U7";
export const PSTAGE_APPOINTMENTS = "YpNwNH9eOFQ";
export const PSTAGE_ENCOUNTER = "bGNvNyvxAv5";
export const VACCINATION_COUNTRY = "HdzfEcDLnhX";

// Appointment Vaccine UUIDs
export const BCG = "yvK623KAkeY";
export const COVID = "QWLb2rXbBk0";
export const DPT = "jCOoLiYg91Y";
export const DPT_HEPB_HIB = "oZpNwR4sDRx";
export const DT = "piqw067k34d";
export const DTAP_HIB_IPV = "bUmHqnfBjlv";
export const HEP_B = "FUzWwCIoLcL";
export const HIB = "iGtOWZybLkl";
export const HPV = "ZoJ22rILjKJ";
export const IPV = "DcPNdrlF1CF";
export const INFLUENZA = "sOHh40wRF3u";
export const MMR = "UXRSTQ5LPWt";
export const MEASLES = "rIEIuTFPAoX";
export const PCV = "k69PBq30zu9";
export const PPD = "Oa3WeTay7AV";
export const RUBELLA = "winsR6vqjxQ";
export const TD = "h2uMl2Df9Ff";
export const VARICELLA = "RErcAOOOO4E";
export const YELLOW_FEVER = "KtwQWtgbQ4N";
export const BOPV = "XTghPGVm1yo";
/// Dictionary of appointment vaccine uuids to their name
export const APPOINTMENT_VACCINE_DICTIONARY = {
    yvK623KAkeY: "BCG",
    QWLb2rXbBk0: "COVID",
    jCOoLiYg91Y: "DPT",
    oZpNwR4sDRx: "DPT-HEPB-HIB",
    piqw067k34d: "DT",
    bUmHqnfBjlv: "DTAP-HIB-IPV",
    FUzWwCIoLcL: "HEP B ",
    iGtOWZybLkl: "HIB",
    ZoJ22rILjKJ: "HPV",
    DcPNdrlF1CF: "IPV",
    sOHh40wRF3u: "INFLUENZA",
    UXRSTQ5LPWt: "MMR",
    rIEIuTFPAoX: "MEASLES",
    k69PBq30zu9: "PCV",
    Oa3WeTay7AV: "PPD",
    winsR6vqjxQ: "RUBELLA",
    h2uMl2Df9Ff: "TD",
    RErcAOOOO4E: "VARICELLA",
    KtwQWtgbQ4N: "YELLOW FEVER",
    XTghPGVm1yo: "BOPV",
};

// Encounter Vaccine UUIDs
/// Dictionary of encounter vaccine given uuids to their name
export const ENCOUNTER_VACCINE_GIVEN_DICTIONARY = {
    ecEkyipcRIv: "BCG",
    QKMp2dNv0fZ: "DPT-HepB-Hib",
    XqpnWfL2KsS: "Polio",
    GrITxyOKMCq: "Polio",
    YVny2cEjzDi: "DPT",
    tN7YmXQu3pL: "MMR",
    tVsni2VJFXc: "Hep B",
    htWcPur81an: "HIB",
    X1ZIrGh9tkm: "HPV",
    YJjjKKbWkw1: "PCV",
    UIt71ly1PI4: "DT",
    QqWenEQ9JKT: "TD",
    SMEeXPN0Z1D: "Varicella",
    niybOGTKrnU: "Rubella",
    Ju5ZrE3pT4w: "Influeza",
    q1fl5Yt047O: "Yellow Fever",
    lq71gF8f90v: "PPD",
    Qrxm4zS7s8s: "Measles",
    JrfMSBTXi0J: "DTap - Hib - IPV",
    AqIXqfrgbOa: "Covid",
};

/// Dictionary of encounter vaccine dose uuids to vaccine uuid
export const ENCOUNTER_VACCINE_DOSE_DICTIONARY = {
    DwNnsBQ8DgN: "ecEkyipcRIv",
    T3ym6J1AXu5: "gbj7bndSjIg",
    RF97WMm6xvd: "XqpnWfL2KsS",
    SS02haqrX0O: "GrITxyOKMCq",
    FRzq2jmdJwO: "YVny2cEjzDi",
    M9lqIaPK3Pa: "tN7YmXQu3pL",
    E0xA6Zci6vX: "tVsni2VJFXc",
    J38K7Ko45of: "htWcPur81an",
    EyuBFb2fGuB: "X1ZIrGh9tkm",
    enKnjQ9NU9o: "YJjjKKbWkw1",
    wO7iPKEUJzv: "UIt71ly1PI4",
    JK1ARKxqJmU: "QqWenEQ9JKT",
    viJQdaj4lmW: "SMEeXPN0Z1D",
    OkFBZw1EFjh: "niybOGTKrnU",
    hzz8Z75VGt2: "Ju5ZrE3pT4w",
    O00SwJ1eWLn: "q1fl5Yt047O",
    q87Bu1pHMiV: "lq71gF8f90v",
    g8nT2U3fnp9: "Qrxm4zS7s8s",
    SnxvJDtU3lJ: "JrfMSBTXi0J",
    TO9V1N5cVNw: "AqIXqfrgbOa",
};

/// Dictionary of vaccines to their batch number
export const ENCOUNTER_VACCINE_BATCH_NUMBER_DICTIONARY = {
    RZyburpEAl7: "BCG",
    umYZGhDSoNV: "Covid",
    Fs1vNC1mSZ5: "DPT",
    bzjTekCoKwA: "DPT-HepB-Hib",
    Kxo37i3xBGF: "DT",
    EDCIn97yY5S: "DTap - Hib - IPV",
    XPevbVHy5k6: "HEP B",
    GaFDJyH3zjL: "HIB",
    J03njnvbYiT: "HPV",
    vrO6jk8muHQ: "Polio",
    n1bjRALXGk0: "MMR",
    VYhLcRFzC38: "Influenza",
    faFiswmXXGx: "Measles",
    EWcInldtPtj: "PCV",
    mLJyjofXj6S: "PPD",
    VhrvI3tG9YT: "Rubella",
    nib4j1Doqc3: "TD",
    vkdMucDsTxo: "Varicella",
    AbsjvagcYZX: "Yellow Fever",
    BlCFtlcBfZc: "Polio",
};
/// DHIS2 Endpoints
export const apiEndpoint = "https://dhis2test.opensolutions.health/api/";
export const buildTrackerApi = ({
                                    organizationalUnit = ORGANIZATION_UNIT_DOMINICA,
                                    program = PROGRAM_EIR,
                                    chid,
                                }) => {
    return `https://dhis2test.opensolutions.health/api/tracker/trackedEntities/?filter=${UUID_CHID}:EQ:${chid}&orgUnit=${organizationalUnit}&ouMode=DESCENDANTS&program=${program}`;
};
export const buildEventsApi = ({
                                   program = PROGRAM_EIR,
                                   organizationalUnit = ORGANIZATION_UNIT_DOMINICA,
                                   programStage,
                                   trackedEntityInstanceId,
                               }) => {
    return `https://dhis2test.opensolutions.health/api/33/events.json?ouMode=DESCENDANTS&program=${program}&programStage=${programStage}&orgUnit=${organizationalUnit}&trackedEntityInstance=${trackedEntityInstanceId}`;
};
